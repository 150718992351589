<template>
    <div id="app">
        <div class="main-box">
            <ve-line :data="dd" height="250px" :colors="colors" :settings="chartSettings"
                     :extend="percentExtend"></ve-line>
        </div>
        <div style="width: 80%;margin-left: 10%">
                <span>
                心率说明<br/>
                正常心率范围因人而异，或因年龄、性别、体质等而不同，一般为60-100次/分，正常成年人安静时的心率有显著的个体差异。<br/>
                一般来说，年龄越小，心率越快，老年人心跳比年轻人慢，女性的心率比同龄男性快。
                同一个人，在安静或睡眠时心率减慢，运动或情绪激动时心率加快，在某些药物或神经体液因素的影响下，会使心率发生加快或减慢。
                    </span>
        </div>
        <div v-bind:key="item.dt" v-for="item in dt" style="margin-top: 10px">
            <div class="title1">{{item.dt}}</div>
            <div class="list_day2">
                <ul>
                    <li v-bind:key="it.tm" v-for="it in item.arr">
                        <div class="pc"><img src="img/xl.png" width="40px"></div>
                        <div class="txt1">
                            <span>{{it.tm}}</span>
                            <span style="color: orange;font-size: large">{{it.value}} 次/分</span>

                        </div>
                        <span style="float: right;margin-top: -3em">{{it.msg}}</span>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
    import VeLine from 'v-charts/lib/line.common'
    import 'v-charts/lib/style.css'


    export default {
        components: {VeLine},
        name: "Hearthistory",
        created() {
            var olddt;
            var j = 0;
            for (var i = 0; i < window.heart.length; i++) {
                var arr = new Array();
                window.heart[i].time = new Date(window.heart[i].created_at.$date - 8 * 60 * 60 * 1000).Format('yy-MM-dd\nhh:mm:ss')
                if (i < 7) {
                    this.dd.rows.push(window.heart[i])
                }
                var dts = new Date(window.heart[i].created_at.$date - 8 * 60 * 60 * 1000).Format('MM月dd')
                var msg;
                if (window.heart[i].heartrate < 60) {
                    msg = '心率过低'
                } else if (window.heart[i].heartrate > 100) {
                    msg = '心率过高'
                } else {
                    msg = '心率正常'
                }
                arr.push({
                    'value': window.heart[i].heartrate,
                    'msg': msg,
                    'tm': new Date(window.heart[i].created_at.$date - 8 * 60 * 60 * 1000).Format('hh:mm')
                })
                if (olddt != dts) {
                    this.dt.push({'dt': dts, 'arr': arr})
                    olddt = dts;
                    j++;
                } else {
                    this.dt[j - 1].arr.push(arr[0])
                }
            }

            this.dd.rows.reverse()
        },
        data() {
            return {
                dd: {
                    columns: ['time', 'heartrate'],
                    rows: []
                },
                dt: [],
                chartSettings: {
                    labelMap: {
                        'time': '日期',
                        'heartrate': '心率',
                    },
                },
                settings: {},
                colors: ['#fc783a', '#f13465'],
                percentExtend: {
                    series: {
                        label: {
                            show: true,
                        }
                    },
                    legend: {
                        bottom: 10
                    }
                },
            }
        },
        methods: {}
    }
</script>